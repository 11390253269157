import * as React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import { createMedia } from "@artsy/fresnel";

// styles
const viewerstyles = {
  maxWidth: "960px",
  margin: "0px auto",
};
const pageStyles = {
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 64,
  marginBottom: 24,
  textAlign: "center",
  fontSize: "48px",
  lineHeight: "58px",
};
const headingAccentStyles = {
  color: "#2563eb",
};
const paragraphStyles = {
  marginBottom: 128,
  fontSize: "20px",
  lineHeight: "30px",
  fontWeight: 400,
  color: "#4d5c74",
  textAlign: "center",
};

// markup
const IndexPage = () => {
  const { Media } = createMedia({
    breakpoints: {
      lg: 1024,
    },
  });

  const isBrowser = typeof window !== "undefined";
  React.useEffect(() => {
    if (isBrowser) {
      if (window.CI360) {
        window.CI360.init();
      }
    }
  }, [isBrowser]);

  return (
    <main style={pageStyles}>
      <Helmet>
        <script src="https://cdn.scaleflex.it/plugins/js-cloudimage-360-view/3.0.1/js-cloudimage-360-view.min.js"></script>
      </Helmet>
      <title>Product Demo</title>
      <div style={viewerstyles}>
        <h1 style={headingStyles}>
          Show
          <span style={headingAccentStyles}> every detail </span>
          from any angle
        </h1>
        <p style={paragraphStyles}>
          Show your customers with a stunning 360 view of your products.
        </p>
        <div
          class="cloudimage-360"
          id="gurkha-suv"
          data-folder="https://scaleflex.cloudimg.io/v7/demo/geely-car/"
          data-filename-x="Okavango-3-{index}.jpg"
          data-amount-x="24"
          data-keys
          data-full-screen
          data-bottom-circle
          data-control-reverse
        ></div>
        <div style={{ textAlign: "center", padding: 16 }}>
          <Link
            style={{ padding: 16, color: "#2563eb", textDecoration: "none" }}
            to="/"
          >
            Demo 1
          </Link>
          <Link
            style={{ padding: 16, color: "#2563eb", textDecoration: "none" }}
            to="/demo2"
          >
            Demo 2
          </Link>
        </div>
      </div>
      <Media greaterThanOrEqual="lg">
        <div
          style={{
            position: "absolute",
            width: "255px",
            height: "255px",
            right: "0",
            top: "30px",
            background: "#bbf7d0",
            filter: "blur(120px)",
            zIndex: "-1",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            width: "418px",
            height: "254px",
            left: "-283px",
            top: "546px",
            background: "#93c5fd",
            filter: "blur(150px)",
            zIndex: "-1",
          }}
        ></div>
      </Media>
    </main>
  );
};

export default IndexPage;
